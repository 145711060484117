






































import Vue from 'vue';
import { RuleObject } from '@/interface';
import { coccMtdsResource_1_project_adjustment } from '@/api/projectDetail';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default Vue.extend({
  name: 'adjustPrice',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      adjustPriceForm: {
        price: null,
      },
      rules: {
        price: [
          { required: true, message: '请输入目标投标价(元)', trigger: 'blur' },
          {
            required: true,
            validator: (rule: RuleObject, value: number, callback: any) => {
              value = Number(value);
              const minPrice = Number(this['minPrice']);
              if (value > minPrice) {
                callback();
              } else {
                callback(new Error('请输入有效的目标投标价'));
              }
            },
            trigger: 'blur',
          },
        ],
      },
      spinning: false,
    };
  },
  computed: {
    ...mapGetters(['projectBidSequenceNbr']),
    minPrice(): string {
      const price = (Number(this.totalPrice) * 0.9).toFixed(2);
      return price;
    },
    maxPrice(): string {
      const price = (Number(this.totalPrice) * 1.1).toFixed(2);
      return price;
    },
  },
  methods: {
    ...mapMutations(['SET_PRICE_ADJUSTMENT_VALUATION']),
    close() {
      this.$emit('update:visible', false);
    },
    adjustPrice() {
      (this.$refs.formRef as HTMLFormElement).validate((valid) => {
        if (!valid) return '';
        const id = this.projectBidSequenceNbr;
        if (!id) return '';
        this.spinning = true;
        this.SET_PRICE_ADJUSTMENT_VALUATION(true);
        let apiData = {
          price: this.adjustPriceForm.price,
        };
        coccMtdsResource_1_project_adjustment(id, apiData)
          .then((res) => {
            this.spinning = false;
            if (res.status === 200) {
              (this.$refs.formRef as HTMLFormElement).resetFields();
              // this.priceAdjustmentOrValuationStatus();
            } else {
              this.SET_PRICE_ADJUSTMENT_VALUATION(false);
            }
          })
          .catch(() => {
            this.spinning = false;
            this.SET_PRICE_ADJUSTMENT_VALUATION(false);
          });
        this.close();
      });
    },
  },
});
